@import "../../../components/bootstrap/less/variables.less";

.search-panel-container {
  position: absolute !important;
  top: 58px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 1000;
  background: @grayBorderLight;
  padding: 0 40px;

  .grid-container {
    margin-top: 2px;
  }

  .grid-actions { //temporary
    display: none;
    position: absolute;
    top: 6px;
    right: 6px;

    span {
      cursor: pointer;
      &:hover {
        color: @greenFontHeader;
      }
    }
  }

  .form-group {
    margin-bottom: 15px;
  }

  .dnd-panel {
    padding: 10px 12px;
  }
}

.quick-search-tabs {
  background: @grayBGLight;
  list-style: none;
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 0;
  border-bottom: 1px solid @white;

  &::after {
    content: '';
    position: absolute;
    top: 101%;
    left: 0;
    right: 0;
    bottom: 5px;
    border-top: 1px solid @grayBorderLight;
  }

  .search-title {
    width: 165px !important;
    font-size: 17px;
  }

  .quick-search-tab {
    display: inline-block;
    text-align: center;
    height: 55px;
    width: 10.7%;
    line-height: 55px;
    border-left: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;

    a {
      color: @grayFontDark;
      text-decoration: none;
    }

    &:not(.license-required):not(.search-title) {
      &.active, &:hover {
        background: @grayBorderLight;
      }

      &.active {
        a {
          color: @greenFontDark;
        }

        &::after {
          content: "";
          display: block;
          border-bottom: 2px solid @grayBorderLight;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.label-style {
  display: inline-block;
  background: white;
  width: 75px;
  float: left;
  font-size: 14px;
  font-weight: normal;
  height: 34px;
  line-height: 34px;
  padding-left: 5px;
  border: 1px solid @grayBorderLighter;
  border-right: none;
  color: @grayFontDark;
}

.field-style {
  width: 380px;
  display: inline-block;
}

.search-wrapper {
  display: block;
  margin-bottom: 10px;
  padding: 30px 50px 0px 0px;

  .row {
    margin-bottom: 0px;
    * {
      margin-bottom: 5px;
    }
    .selectize-input {
      margin-bottom: 0px;
    }
  }

  .field-style {
    margin-right: 10px;
  }

  .jf-checkbox {
    margin-top: 7px;
    span {
      margin-right: 10px;
      vertical-align: text-bottom;
    }
  }

  h2 {
    margin-top: 0;
    font-size: 24px;
  }
}

.search-results-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;

  span span {
    margin: 0 7px 0 5px;
  }
}

.search-panel {
  position: relative;
}

a.clear-all {
  margin-right: 10px;
  color: @grayFontDarker;
  text-decoration: none;
}

.repo-list-dropdown {
  padding-top: 10px;

  .icon {
    font-size: 22px;
    float: left;
    margin: 3px 8px 0 0;
    color: @greenFontHeader;
  }

  .dnd-panel {
    margin-left: -15px;
    max-width: none;
  }

  .dnd-list {
    border: 1px solid @grayBGMedium;
  }

  .dnd-list-fullheight {
    height: 310px;
  }
}


.gap-list {
  height: 32px;
  display: none;
}
.checkbox-search{
  margin-right: 10px;
}
.back-to-browse{
  padding-top: 20px;
}
.search-input {
  width:380px;
}

.search-button {
  width: auto;
  margin-left: 17px;
}

.repo-dnd-title {
  display: inline-block;
  padding-bottom: 10px;
  font-size: 14px;
  color: @greenFontHeader;
  cursor: pointer;

  span {
    line-height: 28px;
  }
}

.search-result-container {
  .filter-group{
    padding-left: 0!important;
  }
}

.multi-selectize {
  .selectize-input {
    background: white;
    min-height: 42px;
  }
}
.selectize-control.single .selectize-input {
  background: white;
}
.any-property {
  + ul {
    width: 24%;
    li {
      width: 100%;
    }
  }
}

#stash-container {
  position: absolute;
  right: 0;
  margin-top: -20px;

  i#stash-animation {
    position: absolute;
    z-index: 1000;
    font-size: 26px;
    color: @grayFontGeneral;
    display: none;
  }

  i.icon-info {
    position: absolute;
    left: 17px;
    top: 4px;
    color: #707070;
  }

  #stash-menu {
    display: inline-block;
    margin-right: 10px;

    #calc-actions {
      display: table;
      width: 100%;
      border-bottom: 1px solid #fff;
      padding-bottom: 2px;

      div {
        display: table-cell;
        text-align: center;

        span {
          display: inline-block;
          width: 16px;
          height: 16px;
          background-size: 100%;
          cursor: pointer;
        }
      }
    }

    #general-actions {
      padding-top: 6px;
      border-top: 1px solid #aaa;

      a {
        color: @grayFontGeneral;
        font-size: 14px;

        i {
          margin-right: 6px;
          position: relative;
          top: 1px;
        }

        &:hover {
          &, i:before {
            color: @blueMain;
          }
        }
        &:not(:first-child) {
          margin-left: 5px;
        }
      }
    }
  }

  #stash-box {
    display: inline-block;
    color: @blueMain;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    min-width: 70px;

    span {
      display: inline-block;
      height: 32px;
      width: 36px;
      position: relative;
      top: 2px;
      left: 2px;
    }
  }
}