// Animations
@-webkit-keyframes swell {
  0% { transform: scale(0); }
  47% { transform: scale(1); }
  50% { transform: scale(1.1); }
  75% { transform: scale(1); }
  100% { transform: scale(1); }
}

@keyframes swell {
  0% { transform: scale(0); }
  47% { transform: scale(1); }
  50% { transform: scale(1.1); }
  75% { transform: scale(1); }
  100% { transform: scale(1); }
}

.swelling {
  -webkit-animation: swell 0.5s linear forwards;
  animation: swell 0.5s linear forwards;
}

.home-page {
  h3 {
    color: @blackFontDark;
    font-size: 32px;
    margin-bottom: 30px;
  }

  .jf-switch {
    margin: 40px 0;

    .jf-switch-options {
      li {
        &:not(:first-child)::before {
          content: "/";
          color: @grayFontLighter;
          padding: 0px 8px 0 12px;
        }
      }
    }
    .jf-switch-option {
      color: @grayFontLighter;

      &:hover, &.active {
        color: @grayFontGeneral;
      }
    }
  }

  .home-page-head {
    margin-top: 20px;
    padding: 0 20px;
  }

  .message-of-day {
    height: 35px;
    line-height: 35px;
    background-color: #fff;
    padding-left: 15px;

    span {
      font-size: 12px;
      color: @blueFontDark;
    }
  }

  .home-page-intro {
    margin-top: 20px;
    position: relative;
    padding-left: 15px;
  }

  .artifactory-version {
    font-size: 16px;
    margin: 0 0 5px;

    a i:before {
      color: @blueLinkBasic;
    }
  }
  
  .number-artifacts {
    font-weight: bold;
  }

  .jfrog-news {
    float: right;
    width: 30%;
    margin: 0 0 10px 10px;

    background-color: #fff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .1);

    div {
      padding-left: 15px;

      &.headline {
        line-height: 40px;
        height: 40px;
        font-size: 18px;
        color: @blueMain;
        border-bottom: 1px solid @blueMain;

        i {
          position: absolute;
          margin: 1px 0 0 10px;
          font-size: 44px;
        }

        .twitter-follow {
          position: absolute;
          right: 10px;
          top: 3px;
        }
      }

      &.news-content {
        padding: 10px 15px 6px;
        max-height: 150px;
        overflow: auto;

        h2 {
          font-size: 16px;
          font-weight: 700;
          margin: 0 0 10px;
        }
      }
    }

    iframe {
      width: 625px;
      height: 100px;
      background-color: #fff;
      border: 0 none;
    }
  }

  .addon-list {
    padding-left: 20px;
  }

  .addon-container {
    position: relative;
    display: inline-block;
    height: 200px;
    background: @grayBGLight;
    margin: 0 20px 15px 0;
    text-align: center;
    transition: 0.5s all;

    &, .addon-name, .addon-status, .addon-links {
      width: 180px;
    }

    &:hover {
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.17);
    }

    .addon-icon {
      // Commented out animation until we get clearance from Yoav
      //transform: scale(0);
      width: 130px;
      height: 70px;
      margin: 35px auto 0;
      background-position: center center;
      background-size: auto 100%;
    }

    .addon-name {
      position: absolute;
      top: 120px;
      font-weight: bold;
      color: @grayFontLighter;
    }

    .addon-status {
      position: absolute;
      top: 145px;

      color: @greenFontHeader;

      &.available {
        color: @blueFontDark;
      }
    }

    .addon-links {
      position: absolute;
      top: 164px;
      font-size: 12px;

      &, a {
        color: @grayFontLighter;
      }

      a:hover {
        color: @greenFontHeader;
      }

      span {
        font-size: 20px;
        margin: 0 4px;
        position: relative;
        top: 3px;
      }

      visibility: hidden;
    }

    &:hover {
      .addon-links {
        visibility: visible;
      }
    }

    &.license-required::after {
      top: 7px;
      right: 12px;
    }
  }

  .today-content {
    margin-left: 5px;
  }
}

#home-disclaimer {
  position: absolute;
  bottom: 0;
  padding: 0 20px 0;
  font-size: 11px;
  color: #bbb;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  a {
    color: #75c2ff;
  }
}

.icon-padlock {
  font-size: 22px;
  position: relative;
  top: 4px;
  margin-right: 4px;
}