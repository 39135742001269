.center-login{
  width: 471px;
  margin: 15% auto;
}

.login-form {
  background: @white;
  padding: 0;

  .login-inner {
    padding: 13px 45px 12px;

    h3 {
      margin-bottom: 20px;
      color: @greenFontHeader;
      font-size: 22px;
    }
  }

  .login-button-bar {
    background: @grayBGLoginLight;
    padding: 18px 45px;
    overflow: auto;
  }

  .close-button {
    color: @grayFontMediumDark;
    padding: 10px 20px 0 0;
    font-size: 22px;

    &:hover {
      text-decoration: none;
    }
  }

  .oauth-login-links-container {
    padding: 5px 0px;
    text-align: center;
    .oauth-login-link {
      display: inline-table;
      line-height: 20px;
      width: 110px;
      word-wrap: break-word;
      white-space: normal;
      padding: 0px;//0px 5px;
      margin: 5px 5px;
      span {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}



