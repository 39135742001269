.ui-select-choices, .actions-dropdown, .vakata-context {
  border-radius: 0;
  background-color: #fff;
  border: 0 none;
  padding: 0;
}

.ui-select-choices .ui-select-choices-row, .actions-dropdown li, .vakata-context li {
  height: 40px;
  line-height: 40px;

  & > a {
    height: 40px;
    line-height: 40px;
    color: @grayFontGeneral;
  }
}

.actions-dropdown, .vakata-context {
  top: 25px;
  min-width: 190px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, .25);

  li {
    display: list-item;
    list-style: none;

    .action-icon {
      width: auto !important;
      font-size: 18px;
      margin: 0 10px 0 12px;
      line-height: 46px !important;

      &::before {
        color: @grayFontGeneral;
      }
    }

    .action-container {
      .action-icon {
        margin-right: 6px;

        &.icon-re-index {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }

    & > a {
      padding: 0;
      font-size: 13px;
      text-shadow: none;

      & > span:not(.action-icon) {
        display: inline-block;
        font-weight: 300;
        color: @grayFontGeneral;
        vertical-align: top;
      }
    }

    & > a:hover, &.vakata-context-hover a {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background: transparent;
    }

    .vakata-contextmenu-sep {
      display: none !important;
    }

    &:first-child {
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 2px;
        right: 8%;
        box-sizing: border-box;

        border: 5px solid black;
        border-color: transparent transparent @white @white;

        transform-origin: 0 0;
        transform: rotate(135deg);

        box-shadow: -3px 2px 2px 0 rgba(0, 0, 0, 0.06);
      }

      &:hover::after {
        border-color: transparent transparent @greenBGDropdownHover @greenBGDropdownHover;
      }
    }

    &:hover {
      background: @greenBGDropdownHover !important;
      box-shadow: inset 3px 0px 0px 0px @greenBGDark;

      & > a, > a > span, .action-icon::before {
        color: @greenFontHeader;
      }

      .action-container {
        background: transparent;
      }
    }
  }
}

//---ui select design---//
.ui-select-container {
  box-shadow: none !important;
  outline: none !important;

  .ui-select-match span.ui-select-toggle:first-child,
  input.ui-select-search {
    outline: none !important;
    padding: 0 10px;
    box-shadow: none !important;
    transition: border-color 0.5s ease;
    border-radius: 0 !important;
    line-height: 40px;
    background: white !important;
    .input-text;

    input:focus {
    }
  }

  span.ui-select-toggle {
    overflow: hidden;
  }

  &[disabled] .ui-select-match span.ui-select-toggle:first-child {
    background: #f5f5f5 !important;
  }

  .btn.btn-default {
    &:hover, &:focus, &:active {
      color: @blackFontDark !important;
    }
  }

  .text-muted {
    .jf-placeholder();
  }
}

.ui-select-choices {
  border-top: 1px solid @grayBorderLighter;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .25);

  .ui-select-choices-row {
    &.active > a, & > a:hover {
      background: @greenBGDropdownHover !important;
      color: @greenFontHeader;

      i:before {
        color: @greenFontHeader;
      }
    }

    & > a {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 1px solid @grayBorderLighter;
    }
  }
}

//-----selectize---------------------------//
.selectize-dropdown-content {
  padding: 0;
}

.selectize-dropdown {
  border: 0 none;
  border-radius: 0;
  margin-top: -1px;

  border-top: 1px solid @grayBorderLighter;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
}

.selectize-dropdown [data-selectable], .selectize-dropdown .optgroup-header {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid @grayBorderLighter;
  color: @grayFontGeneral;

  &.active {
    background: @greenBGDropdownHover !important;
    color: @greenFontHeader;
  }
}

.selectize-control {
  height: 40px;

  &.single .selectize-input {
    &:after {
      border-width: 4px 4px 0 4px;
    }
    &.dropdown-active:after {
      border-width: 0 4px 4px 4px;
    }
  }
}

.labeled-item,
.selectize-control.multi .selectize-input > div,
.selectize-control.multi .selectize-input > div.active {
  position: relative;
  display: inline-block;
  background: transparent;
  padding: 0px 24px 0 8px !important;
  margin: 0 8px 0 0;
  border: 1px solid @blueMain;
  border-radius: 3px;
  color: @grayFontGeneral;
  height: 26px;
  line-height: 24px;

  .remove, .remove:hover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    background: @white;
    font-weight: 400;
    font-size: 22px;
    color: @blueMain;
    width: 24px;
    text-align: center;
  }
}

.selectize-input {
  min-height: 40px;
  max-height: 107px;
  overflow-y: auto;
  line-height: 29px;
  padding: 4px 10px !important;
  .input-text;

  &.dropdown-active {
    border-radius: 0;
  }
}

.ui-select-search,
.ui-select-match,
.selectize-input,
.btn-default-focus {
  width: 100%;
  //      border: 1px solid @grayBorderLighter;
  outline: none;
  box-shadow: none;
  background: none;
  border-radius: 0px;
  input {
    height: 30px;
  }
  .btn {
    height: auto;
  }
  .btn-default:hover,
  &.focus {
    border: 1px solid @grayBorderLighter;
    box-shadow: none;
  }
  span {
    height: 40px !important;
    background: transparent !important;
  }
}