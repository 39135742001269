.dry-run-result {
  max-height: 100px;
  overflow: auto;
  margin-bottom: 0;
  // Close icon
  &::before {
    display: none;
  }
}

label.snippet {
  color: @greenBGDark;
  margin: 5px 0 0;

  .icon {
    position: relative;
    top: 2px;
    color: @grayFontMediumDark;
  }
}

.cell-top-padding {
  > * {
    position: relative;
    top: 34px;
  }
}

.property-modal {
  overflow: hidden !important;

  .dropdown-min-height {
    min-height: 250px;
  }
}

.modal-sm{
  max-width: 600px;
}

.repository-type-modal {
  .modal-footer {
    min-height: 100px;
  }
}