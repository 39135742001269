@charset "UTF-8";

@font-face {
  font-family: "artifactory";
  src:url("../fonts/artifactory.eot");
  src:url("../fonts/artifactory.eot?#iefix") format("embedded-opentype"),
  url("../fonts/artifactory.woff") format("woff"),
  url("../fonts/artifactory.ttf") format("truetype"),
  url("../fonts/artifactory.svg#artifactory") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "artifactory" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "artifactory" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-admin:before {
  content: "z";
}
.icon-css:before {
  content: "f";
}
.icon-deb:before {
  content: "g";
}
.icon-docker:before {
  content: "j";
}
.icon-groovy:before {
  content: "k";
}
.icon-html:before {
  content: "l";
}
.icon-java:before {
  content: "m";
}
.icon-json:before {
  content: "n";
}
.icon-nuget:before {
  content: "q";
}
.icon-pdf:before {
  content: "r";
}
.icon-txt:before {
  content: "u";
}
.icon-xml:before {
  content: "w";
}
.icon-zip:before {
  content: "x";
}
.icon-artifacts:before {
  content: "y";
}
.icon-builds:before {
  content: "\e00d";
}
.icon-rpm:before {
  content: "H";
}
.icon-ruby-gems:before {
  content: "I";
}
.icon-scala:before {
  content: "J";
}
.icon-general:before {
  content: "K";
}
.icon-archive:before {
  content: "d";
}
.icon-c-1:before {
  content: "N";
}
.icon-c:before {
  content: "P";
}
.icon-c-2:before {
  content: "B";
}
.icon-cached-repo:before {
  content: "e";
}
.icon-copy:before {
  content: "i";
}
.icon-group:before {
  content: "p";
}
.icon-go-to-build:before {
  content: "t";
}
.icon-folder:before {
  content: "v";
}
.icon-arrow:before {
  content: "A";
}
.icon-download:before {
  content: "G";
}
.icon-delete-content:before {
  content: "M";
}
.icon-delete-versions:before {
  content: "O";
}
.icon-local-repo:before {
  content: "R";
}
.icon-move:before {
  content: "T";
}
.icon-simple-browser:before {
  content: "U";
}
.icon-re-index:before {
  content: "W";
}
.icon-virtual-repo:before {
  content: "X";
}
.icon-v:before {
  content: "Y";
}
.icon-unview:before {
  content: "Z";
}
.icon-show-in-tree:before {
  content: "1";
}
.icon-show-in-ci-server:before {
  content: "2";
}
.icon-show-build-json:before {
  content: "3";
}
.icon-search-specific:before {
  content: "4";
}
.icon-search:before {
  content: "5";
}
.icon-run:before {
  content: "6";
}
.icon-zap:before {
  content: "7";
}
.icon-refresh:before {
  content: "8";
}
.icon-view:before {
  content: "9";
}
.icon-archive-2:before {
  content: "\"";
}
.icon-delete-recursive:before {
  content: "L";
}
.icon-home:before {
  content: "\e00c";
}
.icon-gradle:before {
  content: "$";
}
.icon-filter:before {
  content: "(";
}
.icon-upload-hover-old:before {
  content: ")";
}
.icon-set-me-up:before {
  content: "*";
}
.icon-notif-success:before {
  content: ",";
}
.icon-notif-error:before {
  content: "-";
}
.icon-copy-to-clipboard:before {
  content: "/";
}
.icon-arrow2:before {
  content: ":";
}
.icon-edit:before {
  content: "o";
}
.icon-artifactory-edit:before {
  content: "C";
}
.icon-js:before {
  content: ";";
}
.icon-bintray:before {
  content: "c";
}
.icon-bintray-old:before {
  content: "o";
}
.icon-jar:before {
  content: "E";
}
.icon-vagrant:before {
  content: "<";
}
.icon-yum:before {
  content: ">";
}
.icon-sbt:before {
  content: "?";
}
.icon-blackduck:before {
  content: "@";
}
.icon-pypi:before {
  content: "[";
}
.icon-git-lfs:before {
  content: "]";
}
.icon-remote-repo:before {
  content: "!";
}
.icon-deploy:before {
  content: "s";
}
.icon-datagrid-v {
  &:before {
    content: "V";
  }
}
.icon-more:before {
  content: "#";
}
.icon-bower:before {
  content: "&";
}
.icon-import:before {
  content: "'";
}
.icon-padlock:before {
  content: "+";
}
.icon-p2:before {
  content: "^";
}
.icon-npm:before {
  content: "_";
}
.icon-notif-icon:before {
  content: "`";
}
.icon-notif-warning:before {
  content: "{";
}
.icon-generic:before {
  content: "|";
}
.icon-export:before {
  content: "}";
}
.icon-duplicate:before {
  content: "~";
}
.icon-drag:before {
  content: "\\";
}
.icon-new:before {
  content: "\e000";
}
.icon-ivy:before {
  content: "\e001";
}
.icon-info:before {
  content: "\e002";
}
.icon-clear:before {
  content: "\e003";
}
.icon-pro:before {
  content: "\e004";
}
.icon-upload:before {
  content: "\e005";
}
.icon-upload-hover:before {
  content: "\e006";
}
.icon-vcs:before {
  content: "\e007";
}
.icon-pom:before {
  content: "\e008";
}
.icon-not-view-nor-watch:before {
  content: "\e009";
}
.icon-re-index-new:before {
  content: "a";
}
.icon-megaphone:before {
  content: "S";
}
.icon-megaphone-on:before {
  content: "0";
}
.icon-unwatch:before {
  content: "D";
}
.icon-watch:before {
  content: "Q";
}
.icon-docker-id:before {
  content: "h";
}
.icon-docker-tags:before {
  content: "%";
}
.icon-docker-command:before {
  content: ".";
}
.icon-docker-created:before {
  content: "=";
}
.icon-docker-size:before {
  content: "\e00a";
}
.icon-docker-digest:before {
  content: "\e00b";
}





.icon-angle-double-up:before {
  content: "\21E7";
  font-size: 22px;
  line-height: 36px !important;
}
.icon-angle-double-down:before {
  content: "\21E9";
  font-size: 22px;
  line-height: 36px !important;
}