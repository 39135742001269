.license-required {
  position: relative;
  color: grey;
  cursor: default !important;

  *:not(a) {
    color: grey;
    cursor: default !important;
  }

  .license-required-icon;

  &:not(.addon-container) a {
    pointer-events: none;
  }
}

.license-required-PRO {
  &::after {

  }
}

.license-required-ENT {
  &::after {

  }
}

.license-required-icon {
  &::after {
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
    width: 20px;

    font-family: "artifactory" !important;
    font-size: 16px;
    content: "\e004";
    color: @blueMain !important;
  }
}